import {get,post} from "@/utils/axios"
// 查询合同
const getContractList = p => get('/api-seller/apiconsole/contract/getContractList', p);

const syncContractCancel = p => post('/api-seller/apiconsole/contract/syncContractCancel', p);

export default {
    getContractList,
    syncContractCancel
}
