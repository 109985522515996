<template>
  <el-col class="page">
    <el-col class="title">合同管理</el-col>
    <el-col class="pane-container">
      <el-col  :class="{ 'pane-active':active=='' }" class="pane cursor-item" @click.native="paneActive('')">全部</el-col>
      <el-col  :class="{ 'pane-active':active==1 }" class="pane cursor-item" @click.native="paneActive('1')">申请中</el-col>
      <el-col  :class="{ 'pane-active':active==2 }" class="pane cursor-item" @click.native="paneActive('2')">处理中</el-col>
      <el-col  :class="{ 'pane-active':active==3 }" class="pane cursor-item" @click.native="paneActive('3')">已处理</el-col>
      <el-col class="date-query">
        <span class="margin-r">申请时间</span>
        <el-date-picker
          v-model="date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期" class="margin-r">
      </el-date-picker>
        <el-button @click.native="getSelectList()">查询</el-button>
      </el-col>
    </el-col>
    <el-col class="table-container">
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%">
        <el-table-column
            prop="contractNumber"
            label="合同编号">
        </el-table-column>
        <el-table-column
            prop="state"
            label="申请状态"
            :formatter ="stateFormatter">
        </el-table-column>
        <el-table-column
            prop="type"
            label="合同类型"
            :formatter ="typeFormatter">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标题">
        </el-table-column>
        <el-table-column
            prop="express"
            label="快递信息">
        </el-table-column>
        <el-table-column
            prop="takeStartDate"
            label="合同生效开始时间">
        </el-table-column>
        <el-table-column
            prop="takeEndDate"
            label="合同生效结束时间">
        </el-table-column>
        <el-table-column
            prop="successDate"
            label="完成时间">
        </el-table-column>
        <el-table-column
            width=180px
            label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="cancel(scope.row)">取消</el-button>
            <el-button type="danger" size="mini" @click="downLoad(scope.row)">下载附件</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col style="margin-top: 20px">
      <Page :total="page.total"  :current="page.pageNo" :page-size="page.pageSize"
            @on-page-size-change="onPageSizeChange" @on-change="changePage" show-total show-elevator style="font-size: 14px"></Page>
    </el-col>
  </el-col>
</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import contractApi from "../../../api/buyer/transaction/contractApi";
Vue.use(iView)
export default {
  name: "contract",
  data() {
    return {
      loading:true,
      date: '',
      active: '',
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 3
      },
    }
  },
  mounted() {
    console.log(123)
    this.getContractList(this.active)
  },
  methods: {
    cancel(row){
      console.log(12312344)
      console.log(row)
      let contractId = [];
      contractId.push(row.contractId)
      this.$confirm('确定要取消合同吗？', '取消合同').then(() => {

        contractApi.syncContractCancel(contractId).then(res => {
          if(res.code !== 200) {
            this.buyerMsg(res.message, 'error');
            return;
          }
          this.buyerMsg('取消成功');
          this.page.pageNo = 1;
          this.getContractList(this.active)
        })
      })
    },
    downLoad(row){
      window.location.href = row.enclosureUrl;
      console.log(123123)
      console.log(row)
    },
    onPageSizeChange(index) {
      this.page.pageSize = index;
      this.getContractList(this.active)
    },
    changePage(index) {
      this.page.pageNo = index;
      this.getContractList(this.active)
    },
    getSelectList(){
      this.getContractList(this.active)
    },
    paneActive(id) {
      this.active = id;
      this.getContractList(id)
    },
    getContractList(state){
      this.tableData = [];
      let param = {pageNo: this.page.pageNo, pageSize: this.page.pageSize};
      if(state) {
        param.state = state;
      }
      if(this.date){
        console.log(this.date)
        /*var regEx = new RegExp("\\-","gi");
        let dependedVal=this.date.replace(regEx,"/");
        var dependedDate2 = new Date(dependedVal);
        param.createTime = dependedDate2*/
        param.createTime = this.date
      }
      this.page.total = 0;
      contractApi.getContractList(param).then((res) => {
        this.timer = setTimeout(()=>{   //设置延迟执行
          this.loading = false
        },300)
        if(res.data) {
          this.tableData = res.data.records;
          this.page.total = res.data.total
        }
      })
    },
    stateFormatter(row){
      //1:申请中、2:受理中、0:已寄出
      switch (row.state){
        case 1:
          return '申请中';
        case 2:
          return '受理中';
        case 3:
          return '已寄出';
        case 4:
          return '已取消';
        default:
          return '';
      }
    },
    typeFormatter(row){
      //合同类型 1：邮寄 2：附件
      switch (row.type){
        case 1:
          return '邮寄';
        case 2:
          return '附件';
        default:
          return '';
      }
    }
  }
}
</script>
<style>
.el-button--primary  {
  background: #f65d59 !important ;
  border-color: #f65d59 !important;
}
</style>
<style lang="less" scoped>
.cursor-item {
    cursor: pointer;
}
.page {
  margin: 20px 35px;
  width: 97%;
  .title {
    color: #F56E65;
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  .pane-container {
    height: 34px;
    //margin: 20px 35px;
    .pane {
      width:48px;
      height: 16px;
      font-size: 16px;
      margin-right: 40px;
      color: rgba(65, 65, 65, 1);
    }
    .date-query {
      height: 100%;
      font-size: 14px;
      float: right;
      margin-right: 70px;
      width: 370px;
      .margin-r {
        margin-right: 15px;
      }
    }
    /deep/ .el-button {
      color: #fff;
      background-color: #ed4014;
      border-color: #ed4014;
      width: 60px;
      height: 32px;
      padding: 7px 15px;
    }
    /deep/ [data-v-23543608] .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .pane:active {
      color: #F56E65;
    }
    .pane:hover {
      color: #F56E65;
    }
    .pane-active {
      color: #F56E65;
    }

  }
  .table-container {
    margin-top: 20px;
    /deep/.el-table .cell, .el-table--border .el-table__cell:first-child .cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    /deep/ .el-table thead {
      color: #515A6E;
    }
  }
  /deep/ .ivu-page {
    float: right;
    margin-right: 143px;
  }
}
</style>
